import React from "react";
import Layout from "../../components/Layout";
import img1 from "./img/1.png";
import img2 from "./img/2.png";
import img3 from "./img/3.png";
import img4 from "./img/4.png";
import img5 from "./img/5.png";

const Contact = () => {
  const [level, setLevel] = React.useState(0);
  const [state, setState] = React.useState({
    name: "",
    service: "",
    email: "",
    special: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    // Solo avanzará si el campo actual está lleno
    if (
      (level === 0 && state.name) ||
      (level === 1 && state.service) ||
      (level === 2 && state.email) ||
      (level === 3 && state.special)
    ) {
      setLevel(level + 1);
    } else {
      alert("Por favor, completa el campo antes de continuar.");
    }
  };

  return (
    <Layout>
      <section
        className="pb-[200px] pt-[100px] md:pt-[128px] md:pb-[333px] xl:pt-[278px] xl:pb-[533px] relative overflow-hidden"
        style={{
          background: `url('/img/contact/contact-bg.png') no-repeat center center / cover`,
        }}
      >
        <div className="container relative z-10">
          <div className="">
            <>
              <h1 className="font-bold text-2xl md:text-4xl lg:text-6xl xl:text-[64px] xl:leading-[1.5] mb-5">
                {level === 0
                  ? "¿Cuál es tu nombre?"
                  : level === 1
                  ? "¿Qué servicio te interesa?"
                  : level === 2
                  ? "¿Cuál es tu correo?"
                  : level === 3
                  ? "¿Algún requerimiento especial?"
                  : "¡Gracias por contactarnos!"}
              </h1>

              <form onSubmit={handleSubmit}>
                {level === 0 ? (
                  <input
                    type="text"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu nombre completo"
                    value={state.name}
                    onChange={(e) =>
                      setState({ ...state, name: e.target.value })
                    }
                    required
                  />
                ) : level === 1 ? (
                  <input
                    type="text"
                    className="form-input form-input-2"
                    placeholder="Ingresa el servicio que te interesa"
                    value={state.service}
                    onChange={(e) =>
                      setState({ ...state, service: e.target.value })
                    }
                    required
                  />
                ) : level === 2 ? (
                  <input
                    type="email"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu correo"
                    value={state.email}
                    onChange={(e) =>
                      setState({ ...state, email: e.target.value })
                    }
                    required
                  />
                ) : level === 3 ? (
                  <input
                    type="text"
                    className="form-input form-input-2"
                    placeholder="Ingresa tu requerimiento especial"
                    value={state.special}
                    onChange={(e) =>
                      setState({ ...state, special: e.target.value })
                    }
                    required
                  />
                ) : (
                  <p className="text-md md:text-xl lg:text-4xl xl:text-5xl 2xl:text-6xl lg:max-w-[870px] 2xl:max-w-[1072px]">
                    En un lapso de 24hrs uno de nuestros especialistas se pondrá
                    en contacto contigo.
                  </p>
                )}
              </form>
            </>
          </div>
        </div>
        <img
          src={
            level === 0
              ? img1
              : level === 1
              ? img2
              : level === 2
              ? img3
              : level === 3
              ? img4
              : img5
          }
          className="absolute left-[31px] bottom-12 sm:bottom-12 xl:bottom-[102px] max-xl:w-[calc(100vw-62px)] 2xl:w-[unset] md:w-[70vw] z-10"
          alt=""
        />
        <img
          src="/img/contact/right.png"
          className="absolute right-0 bottom-[85px] max-xl:w-[300px] max-xl:bottom-0 max-md:hidden"
          alt=""
        />
      </section>
    </Layout>
  );
};

export default Contact;
