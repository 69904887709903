import React from "react";

const Indoor = () => {
	return (
		<section className="pt-[99px] pb-[73px] relative overflow-hidden bg-title">
			<div className="container relative z-[11]">
				<h1 className="text-4xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold mb-[18px]">
					<div className="flex items-center text-primary gap-4 md:gap-7">
						<img
							src="/img/proyectos/arrow.png"
							alt=""
							className="max-md:w-10"
						/>
						<span>Indoor</span>
					</div>
				</h1>
				<img src="/img/proyectos/outdoor.webp" alt="" className="w-full" />
				<h1 className="text-xl sm:text-5xl md:text-6xl xl:text-[50px] xl:leading-[64px] 2xl:text-[64px] 2xl:leading-[78px] font-bold text-end mt-[29px] text-white">
					#LaHacemosEnGrande
				</h1>
			</div>
			<div className="absolute inset-0 before:absolute before:inset-0 before:z-10">
				<img
					src="/img/proyectos/indoor.png"
					alt=""
					className="absolute right-0 top-0 min-h-full w-1/2 max-w-[690px] object-cover object-right-top hidden md:block"
				/>
			</div>
		</section>
	);
};

export default Indoor;
